import classNames from 'classnames';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import './Companies.scss';

interface BrandListType {
    image: string;
    name: string;
}

interface BrandsTypes {
    title: string;
    brandList: BrandListType[];
    areUniversities?: boolean;
}

const Brands = ( {
    title,
    brandList,
    areUniversities = false
}:BrandsTypes) => {
    return (
        <>
            <h3>{title}</h3>
            <div className={classNames("brands-wrapper" , {
                universities: areUniversities
            })}>
                {brandList.map(({image, name}, idx) => (
                    <figure key={idx}>
                        <img src={image} alt={name} title={name} loading='lazy' decoding='async'/>
                    </figure>
                ))}
            </div>
        </>
    );
};


const Companies = () => {

 const companies: BrandListType[] = [
    {
        image: `${CDN_HOST}/images/eAlliance.png`,
        name: 'eAlliance'
    },
    {
        image: `${CDN_HOST}/images/honeywell.png`,
        name: 'Honeywell'
    },
    {
        image: `${CDN_HOST}/images/Revolut.png`,
        name: 'Revolut'
    },
    {
        image: `${CDN_HOST}/images/salesforce.png`,
        name: 'Salesforce'
    },
    {
        image: `${CDN_HOST}/images/sans.png`,
        name: 'Sans'
    },
    {
        image: `${CDN_HOST}/images/steamed-latam.png`,
        name: 'Steamed Latam'
    },
    {
        image: `${CDN_HOST}/images/excellence-innovation.png`,
        name: 'Global Excellence Innovation'
    },
    {
        image: `${CDN_HOST}/images/charlie-datamine.png`,
        name: 'Charlie Data Mine'
    },
    {
        image: `${CDN_HOST}/images/verizon.png`,
        name: 'Verizon'
    },
    {
        image: `${CDN_HOST}/images/BBglobal.png`,
        name: 'BBglobal'
    },
    {
        image: `${CDN_HOST}/images/empower.png`,
        name: 'Empower'
    },
    {
        image: `${CDN_HOST}/images/intel-logo.png`,
        name: 'Intel'
    },
    {
        image: `${CDN_HOST}/images/moonpreneur-logo.png`,
        name: 'Moonpreneur'
    },
    {
        image: `${CDN_HOST}/images/nicaragua-creativa.png`,
        name: 'Nicaragua Creativa'
    },
    {
        image: `${CDN_HOST}/images/edx.png`,
        name: 'edx'
    },
    
   /*  {
        image: `${CDN_HOST}/images/cognite.png`,
        name: 'Cognite'
    }, */
    {
        image: `${CDN_HOST}/images/TNN.png`,
        name: 'The Neurological Nomad'
    }
];

const universities: BrandListType[] = [
    /* {
        image: `${CDN_HOST}/images/itsa.png`,
        name: 'ITSA'
    },
    {
        image: `${CDN_HOST}/images/university-maryland.png`,
        name: 'University Of Maryland'
    }, */
    {
        image: `${CDN_HOST}/images/universidad-del-norte.png`,
        name: 'Universidad del norte'
    },
    {
        image: `${CDN_HOST}/images/universidad-nacional-de-ingenieria.png`,
        name: 'Universidad de Ingenieria'
    },
    {
        image: `${CDN_HOST}/images/rutgers.png`,
        name: 'Rutgers'
    },

]


 const { t } = useTranslation(["homeTranslations"]) 

  return (
    <section className='Companies'>
        <h2>{t("withSpeakers")}</h2>
        <Brands title={t('companies')} brandList={companies}/>
        <hr />
        <Brands title={t('universities')} brandList={universities} areUniversities/>
    </section>
  )
}

export default Companies;