import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
      <App tab="home"/>
    </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
