import classNames from 'classnames';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';
import "./Schedule.scss";

interface ListItemModel {
   time: string;
   title: string;
   endSection?: boolean | null;
   content?: string | null;
}


const ListItem = ({time, title, endSection = false,  content = null } : ListItemModel) => {
    return(
        <div className={classNames('ListItem', {
         border: endSection
        })}>
            <div className="time">
                <span className='icon' style={{backgroundImage: `url(${CDN_HOST}/icons/icon-clock-blue.svg)`}}></span>
                <p>{time}</p>
            </div>

            <div className="content">
                <h3>{title}</h3>
                {(content) ? <p>{content}</p> : null}
            </div>
        </div>
    )
}

const Schedule = () => {
 const { t } = useTranslation(["homeTranslations"]);
 const dayOne: ListItemModel[] = [
     {
        time: "12:00pm",
        title: t("topic1"),
        endSection: true,
        content: t("content1")
     }, 
     {
        time: "1:00pm",
        title: t("topic2"),
        endSection: true,
        content: t("content2")
     }, 
     {
        time: "2:00pm",
        title: t("topic3"),
        endSection: true,
        content: t("content3")
     }, 
     {
        time: "3:00pm",
        title: t("topic4"),
        endSection: false,
        content: t("content4")
     }, 
];

const dayTwo: ListItemModel[] = [
    
   {
      time: "12:00pm",
      title: t("topic5"),
      endSection: true,
      content: t("content5")
   }, 
   {
      time: "1:00pm",
      title: t("topic6"),
      endSection: true,
      content: t("content6")
   }, 
   {
      time: "2:00pm",
      title: t("topic7"),
      endSection: true,
      content: t("content7")
   }, 
   {
      time: "3:00pm",
      title: t("topic8"),
      endSection: false,
      content: t("content8")
   },  
]
  return (
    <section className='Schedule'>
        <h2>{t("schedule")}</h2>
        <h3>{t("dayOne")}</h3>
       <div className="schedule-wrapper">
       <span className='full-date'>Oct 26, 2022</span>
       {dayOne.map((time, idx) => (
           <ListItem 
            time={time?.time}
            title={time?.title}
            content={time?.content}
            endSection={time?.endSection}
            key={idx}
            />
       ))}
       </div>

       <h3>{t("dayTwo")}</h3>
       <div className="schedule-wrapper">
       <span className='full-date dayTwo'>Oct 27, 2022</span>
       {dayTwo.map((time, idx) => (
           <ListItem 
            time={time?.time}
            title={time?.title}
            content={time?.content}
            endSection={time?.endSection}
            key={idx}
            />
       ))}
       </div>
    </section>
  )
}

export default Schedule