import React from "react";
import { CDN_HOST } from "env/env.dev";

import "./FooterLinks.scss";
interface FooterLinksProps {
  link?: string;
}

const FooterLinks = ({link}: FooterLinksProps) => {
  const twitter  = `${CDN_HOST}/icons/icon-twitter-white.svg`,
        linkedin = `${CDN_HOST}/icons/icon-linkedin-white.svg`,
        facebook = `${CDN_HOST}/icons/icon-facebook-white.svg`,
        youtube  = `${CDN_HOST}/icons/icon-youtube-white.svg`;

  return (
    <div className="FooterLinks">
      <div className="social-links-container">
        <div className="social-links">
          <a href="https://twitter.com/DREAMTOSUCCEED" aria-label="Twitter" target="_blank" rel="noopener noreferrer" className="link" >
            <img  src={twitter} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/dream-to-succeed-us/" aria-label="Linkedin" target="_blank" rel="noopener noreferrer" className="link" >
            <img  src={linkedin} alt="LinkedIn" />
          </a>
          <a href="https://www.facebook.com/dreamtosucceedus1" aria-label="Facebook" target="_blank" rel="noopener noreferrer" className="link" >
            <img  src={facebook} alt="Facebook" />
          </a>
          <a href="https://www.youtube.com/channel/UCqa_f3kUNHfM5GdzxKVYxzw" aria-label="Youtube" target="_blank" rel="noopener noreferrer" className="link" >
            <img  src={youtube} alt="Youtube" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;

