import React, { useRef, useState } from 'react';
import "./CardDetail.scss";

interface CardDetailPropsTypes {
    title?: string;
    content?: string;
    color?: string;
    icon?: string;
    iconWhite?: string
}

const CardDetail = ({
    title = "Title",
    content = "Lorem ipsum dolor amet adds off consectetur adipiscing.This pharetra this posuere consectetur.",
    color = "#005566",
    icon, 
    iconWhite
}:CardDetailPropsTypes) => {
    const cardRef = useRef<HTMLElement>();
    const [currentIcon, setCurrentIcon] = useState(icon);

  return (
    <article
     ref={cardRef}
     className='CardDetail'
     onMouseOver={() => {
         cardRef.current.style.backgroundColor = color;
         setCurrentIcon(iconWhite);
     }}
     onMouseLeave={() => {
         cardRef.current.style.backgroundColor = "white";
         setCurrentIcon(icon);
     }}
    >
        <div className='title-wrapper'>
            <span className='icon' style={{ backgroundImage: `url(${currentIcon})` }}></span>
            <h3>{title}</h3>
        </div>
        <p>{content}</p>
    </article>
  )
}

export default CardDetail