import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Footer } from "components/molecules";

import "./App.scss";

const Home  = lazy(() => import("components/views/Home").then(({ Home }) => ({default: Home})));
const AboutSpeaker  = lazy(() => import("components/views/AboutSpeaker").then(({ AboutSpeaker }) => ({default: AboutSpeaker})));

const App = () => {
  return (
    <Suspense fallback="loading">
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/speaker/:name" element={<AboutSpeaker />}/>
      </Routes>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
