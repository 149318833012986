import React, { useState, useEffect, useRef, createRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useScroll } from 'hooks';
import { Link } from 'react-router-dom';
import { CDN_HOST } from 'env/env.dev';
import { UISections } from 'models';
import { useViewport } from "hooks";

import './Header.scss';


interface HeaderProps {
    pageTitle?: string;
    pageView?: string;
    user?: boolean;
    dark?: boolean;
    setContent?: React.Dispatch<React.SetStateAction<UISections>>;
}

interface LanguageSelectorProp {
    currentLanguage: string; 
    secondLanguage: string, 
    thirdLanguage: string;
}

const Header = ({ 
    pageTitle, 
    pageView = "home",
    dark = true,
    setContent
 }: HeaderProps) => {

    const LogoIMG           = `${CDN_HOST}/icons/icon-logo-desktop.svg`,
          LogoDarkIMG       = `${CDN_HOST}/icons/icon-logo-desktop-dark.svg`,
          LogoMobileDarkIMG = `${CDN_HOST}/icons/icon-logo-mobile-dark.svg`,
          menuOpenIcon      = `${CDN_HOST}/icons/icon-menu.svg`,
          menuOpenIconDark  = `${CDN_HOST}/icons/icon-menu-icon-dark.svg`,
          menuCloseIcon     = `${CDN_HOST}/icons/icon-menu-close.svg`,
          usaIMG            = `${CDN_HOST}/images/united-states.png`,
          spainIMG          = `${CDN_HOST}/images/spain.png`,
          brazilIMG         = `${CDN_HOST}/images/brazil.png`;
      
    
    const { t, i18n } = useTranslation(["pageTitles"]),
          [logo, setLogo] = useState(LogoIMG),
          [logoMobile, setLogoMobile] = useState(LogoMobileDarkIMG),
          [isMenuOpen, setIsMenuOpen] = useState(false),
          [dropdownMenuOpen, setDropdownMenuOpen] = useState(false),
          [menuIMG, setMenuIMG] = useState(menuOpenIcon),
          [laguageIMGPreselected, setLanguageIMGPreselected] = useState(''),
          [languageIMGSelected, setLanguageIMGSelected] = useState(''),
          [languagueIMGOption, setLanguageIMGOption] = useState(''),
          [languageLabels, setLanguageLabels] = useState<LanguageSelectorProp>(),
          { yOffset } = useScroll(),
          [viewport] = useViewport();

    const navigationFloat = useRef<HTMLElement>();
    const menu = useRef<HTMLSpanElement>();
    const iconMenu = useRef<HTMLElement>();

    const viewOptionsRef =  useRef<any[]>(
        Array(6)
            .fill(0)
            .map(() => createRef())
    );

    useEffect(() => {
        if(i18n.language === "en") {
            setLanguageIMGPreselected(usaIMG);
            setLanguageIMGSelected(spainIMG);
            setLanguageIMGOption(brazilIMG);
            setLanguageLabels({
                currentLanguage: 'English',
                secondLanguage: 'Cambiar a Español',
                thirdLanguage: 'Cambiar idioma a Portugueseés'
            })
        } else if(i18n.language === "es") {
            setLanguageIMGPreselected(spainIMG)
            setLanguageIMGSelected(usaIMG);
            setLanguageIMGOption(brazilIMG)
            setLanguageLabels({
                currentLanguage: 'Español',
                secondLanguage: 'Change to English',
                thirdLanguage: 'Mudar o idioma para Português'
            })
        } else if(i18n.language === "pt") {
            setLanguageIMGPreselected(brazilIMG);
            setLanguageIMGSelected(usaIMG);
            setLanguageIMGOption(spainIMG);
            setLanguageLabels({
                currentLanguage: 'Português',
                secondLanguage: 'Change to English',
                thirdLanguage: 'Cambiar a Español'
            })
        }

    }, [i18n.language]);


    const handleMenuOpen = () => {
        if(navigationFloat.current) {
            const navMenu = navigationFloat.current,
                  menuIcon = menu.current,
                  menuSVG = iconMenu.current;

            if(navMenu.classList.contains('isOpen')) {
                navMenu.classList.remove('isOpen');
                menuIcon.style.position =  'relative';
                menuIcon.style.right = '0';
                menuIcon.style.top = '0';
                menuSVG.style.width = '30px';
                menuSVG.style.height = '30px';
                setIsMenuOpen(false);
            } else {
                navMenu.classList.add('isOpen');
                menuIcon.style.position = 'fixed';
                menuIcon.style.right = '38px'; 
                menuIcon.style.top = '30px';
                menuSVG.style.width = '22px';
                menuSVG.style.height = '22px';
                setIsMenuOpen(true);
            }
        }
    };


    const handleLanguageSelected = (img) => {

        if(img === spainIMG) {
            setLanguageIMGPreselected(spainIMG);
            setLanguageIMGSelected(usaIMG);
            setLanguageIMGOption(brazilIMG);
            i18n.changeLanguage("es");
        } else if(img === usaIMG){
            setLanguageIMGPreselected(usaIMG);
            setLanguageIMGSelected(spainIMG);
            setLanguageIMGOption(brazilIMG);
            i18n.changeLanguage("en");
        } else if(img === brazilIMG){
            setLanguageIMGPreselected(brazilIMG);
            setLanguageIMGSelected(usaIMG);
            setLanguageIMGOption(spainIMG);
            i18n.changeLanguage("pt");
        }
    };

    useEffect(() => {
        if(dark) {
            isMenuOpen 
                ? setMenuIMG(menuCloseIcon) 
                : setMenuIMG(menuOpenIconDark);
        } else {
            isMenuOpen 
            ? setMenuIMG(menuCloseIcon) 
            : setMenuIMG(menuOpenIcon);
        }
    }, [isMenuOpen, dark]);

    
    const onSubmenuOpen = (element) => {
        const listItem = element.current;
        const submenu =  listItem.querySelector('ul');
        const dropdownArrow =  listItem.querySelector('span');

        submenu.style.display = 'block';
        dropdownArrow.style.transform ='rotateZ(180deg)';
        setDropdownMenuOpen(true);
    }

    const onSubmenuClosed = (element) => {
        const listItem = element.current;
        const submenu =  listItem.querySelector('ul');
        const dropdownArrow =  listItem.querySelector('span');

        submenu.style.display = 'none';
        dropdownArrow.style.transform ='rotateZ(0deg)';
        setDropdownMenuOpen(false);
    }

    const handleDropdownMenu = (element) => {
        if(dropdownMenuOpen){
            onSubmenuClosed(element);
        } else {
            onSubmenuOpen(element);
        }
    }

    useLayoutEffect(() => {
        (pageView === "home" && yOffset <= 37) 
            ? setLogo(LogoIMG) 
            : setLogo(LogoDarkIMG);
    }, [pageView, yOffset]);


    return (
        <header className={classNames("Header", {
            fixed: yOffset > 37,
            absolute: pageView === "home" && yOffset <= 37
        })}>
            <div className="wrapper">
                
                <div className="header__logo-container">
                    <Link aria-label='Dream to succeed home' to="/" rel="noopener noreferrer" onClick={() => setContent({view: 'home'})}>
                        <figure className="Header__logo">
                            <picture>
                                <source media="(min-width: 992px)" srcSet={logo} />
                                <img src={logoMobile} alt="Dream to Succeed" width="50" height="60" loading="lazy" />
                            </picture>
                        </figure>
                    </Link>

                    <h1>{pageTitle}</h1>

                    <i className="Header__menu-icon" ref={menu}>
                        <figure className="menu-icon" ref={iconMenu}>
                            <img onClick={handleMenuOpen}  src={menuIMG} alt="open menu" width="30" height="30" loading="lazy" />
                        </figure>
                    </i>
                </div>

               <div className="Header__menu-wrapper"> 
                    <nav className="Header__navigation-menu" role="navigation" ref={navigationFloat}>
                        <ul>
                            <li className={classNames({
                                currentView: pageView === "home"
                            })} ref={viewOptionsRef.current[0]}
                                onClick={() => {
                                    setContent({view: "home"});
                                    if(viewport === "phone" || viewport === "tablet") {
                                        handleMenuOpen();
                                    }
                                }}
                            >   {t("headerHome")}
                            </li>
                            <li className={classNames({
                                currentView: pageView === "schedules"
                            })} ref={viewOptionsRef.current[1]}
                                onClick={() => {
                                    setContent({view: "schedules"});
                                    if(viewport === "phone" || viewport === "tablet") {
                                        handleMenuOpen();
                                    }
                                }}
                            >{t("headerSchedules")}</li>
                            <li className={classNames({
                                currentView: pageView === "speakers"
                            })} ref={viewOptionsRef.current[2]}
                                onClick={() => {
                                    setContent({view: "speakers"});
                                    if(viewport === "phone" || viewport === "tablet") {
                                        handleMenuOpen();
                                    }
                                }}
                            >{t("headerSpeakers")}</li>
                            <li className={classNames({
                                currentView: pageView === "register"
                            })} ref={viewOptionsRef.current[3]}
                                onClick={() => {
                                    if(viewport === "phone" || viewport === "tablet") {
                                        handleMenuOpen();
                                    }
                                }}
                            ><a className='register-btn' href='https://zoom.us/j/95736517223?pwd=ZlI3M09FZDN3UDFURHJ3UDIyQ3ByZz09' rel='noopener noreferrer' target='_blank'>{t("goEvent")}</a></li>
                            

                        </ul>

                        <div className="navigation__login">
        
                            <div className="language-wrapper">
                                <figure 
                                    className="language-selection"
                                    aria-label={languageLabels?.currentLanguage}>
                                    <img 
                                        src={laguageIMGPreselected}
                                        alt={languageLabels?.currentLanguage} 
                                        width="24" 
                                        height="24" 
                                        loading="lazy" />
                                </figure>
                                <figure 
                                    onClick={() => handleLanguageSelected(languageIMGSelected)} 
                                    className="language-selection option"
                                    aria-label={languageLabels?.secondLanguage}>
                                    <img 
                                        src={languageIMGSelected} 
                                        alt={languageLabels?.secondLanguage} 
                                        width="24" 
                                        height="24" 
                                        loading="lazy"/>
                                </figure>
                                <figure 
                                    onClick={() => handleLanguageSelected(languagueIMGOption)} 
                                    className="language-selection option"
                                    aria-label={languageLabels?.thirdLanguage}>
                                    <img 
                                        src={languagueIMGOption} 
                                        alt={languageLabels?.thirdLanguage} 
                                        width="24" 
                                        height="24" 
                                        loading="lazy"/>
                                </figure>
                            </div>
                    </div>
                    </nav>
                    
               </div>
            </div>
            
        </header>
    )
}

export default Header;
