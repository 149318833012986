import React from "react";
import { CDN_HOST } from "env/env.dev";
import { useTranslation } from "react-i18next";

import "./Events.scss";

const Events = () => {
  const { t } = useTranslation(["homeTranslations"]);
  const eventImage = `${CDN_HOST}/images/mega-event-october.webp`;
  const registerForm =
    "https://www.eventbrite.com/e/the-future-of-work-for-latinx-tickets-268475366047?aff=ebdsoporgprofile";

  return (
    <section className="Events">
      <div className="wrapper">
        <div className="Events__title">
          <h4>{t("eventsTitle")}</h4>
          <h3>{t("eventsSubtitle")} {t("eventsSubtitleSecond")}</h3>
          <br />
        </div>

        <figure className="Event__image">
          <img src={eventImage} alt="Feature Event" loading="lazy" width="336" height="284" />
        </figure>

        <div className="Event__content-wrapper">
          <p>{t("eventsContent")}</p>
          <div className="Event__context">
            <div className="Event__context-text">
              <span className="icon-event calendar-icon"></span>
              <p>{t("eventTime")}</p>
            </div>
            <div className="Event__context-text">
              <span className="icon-event clock-icon"></span>
              <p>12:00 - 16:00 EST</p>
            </div>
            <div className="Event__context-text">
              <span className="icon-event type-event"></span>
              <p>{t("virtualEvent")}</p>
            </div>
          </div>
          <a
            className="btn-primary"
            href={registerForm}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("eventsButton")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Events;
