import React from 'react';
import { CDN_HOST } from 'env/env.dev';
import { useTranslation } from 'react-i18next';


import './EventsHero.scss';

const EventsHero = () => {

    const { t } = useTranslation(["events"]);
    const eventHeroIMG = `${CDN_HOST}/images/events-section.png`;
    return (
        <section className="EventsHero">
            <div className="wrapper">
                <h2>{t("eventsTitle")}</h2>
                <div className="EventsHero__content">
                    <p>{t("eventsTextOne")}</p>
                    <p>{t("eventsTextTwo")}</p>
                </div>

                <figure className="EventsHero__image">
                    <img src={eventHeroIMG} alt="Our Events" width="540" height="422" loading="lazy" />
                </figure>
            </div>
        </section>
    )
}

export default EventsHero;
