import { useEffect } from 'react';
import { speakersMock } from 'data';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CDN_HOST } from 'env/env.dev';
import './SpeakerBiography.scss';

const SpeakerBiography = () => {
    const { t } = useTranslation(["whoWeAre"]);
    const navigate = useNavigate();
    const { pathname }  = useLocation();
    const speakerName = pathname.replace('/speaker/', '');
    const [speakerInfo] = speakersMock.filter( speaker => speaker.name === speakerName);
    
    useEffect(() => {
      
      window.scrollTo(0,0);

    }, [pathname])

    
    

  return (
    <>
    <header className='bio-header' style={{ backgroundImage: `url(${CDN_HOST}/images/speaker-bio-background.png)`}}>
      <h1>{t(`${speakerInfo.name}`)}</h1>
      <div className="navigation">
        <button 
          className='goback-btn' 
          aria-label={t("goBack")}
          onClick={() => navigate(-1)}>
          <strong>←</strong> {`${t("goBack")}`}
        </button>

        <Link to='/' rel='noopener noreferrer'>
        <figure className="Header-logo">
              <picture>
                <source media="(min-width: 992px)" srcSet={`${CDN_HOST}/icons/icon-logo-desktop.svg`} />
                <img src={`${CDN_HOST}/icons/icon-logo-mobile.svg`} alt="Dream to Succeed" width="50" height="60" loading="lazy" />
              </picture>
            </figure>
        </Link>
      </div>
    </header>
      <section className='SpeakerBiography fadeInLeft'>
        <figure>
          <img 
            src={speakerInfo.image} 
            alt={t(`${speakerInfo.name}`)} 
            width={530} 
            height={570}  
            loading='lazy' 
            decoding='async'/>
        </figure>

        <article className='speaker-bio fadeInRight'>
          <h2>{t(`${speakerInfo.name}`)}</h2>
          <hr />
          {(speakerInfo.bio) 
            ? (speakerInfo.bio.map((text, idx) => 
              <p key={idx}>{t(`${text}`)}</p>
            ))
            : <p>{t(`${speakerInfo.position}`)}</p>}
        </article>
      </section>
    </>
  )
}

export default SpeakerBiography