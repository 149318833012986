import { Speaker } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import { speakersMock } from 'data';
import "./Speakers.scss";

const Speakers = () => {

    const { t } = useTranslation(["homeTranslations"]);
  return (
    <section className='Speakers'>
        <h2>{t("speakers")}</h2>
        <div className="speakers-wrapper">
        {speakersMock.map(({name, position, image}, idx) => (
            <Speaker
                key={idx} 
                speakerImage={image}
                speakerName={name}
                speakerRole={position}
            />
        )) }
        </div>
    </section>
  )
}

export default Speakers