import { CDN_HOST } from "env/env.dev";

const EdgarRivera         = `${CDN_HOST}/images/EdgarRiveraSpeaker.png`,
      KristopherHoce      = `${CDN_HOST}/images/KristopherHoce.png`,
      CesarGarci          = `${CDN_HOST}/images/CesarGarci.png`,
      Sayten              = `${CDN_HOST}/images/Sayten.png`,
      MontySantiago       = `${CDN_HOST}/images/MontySantiago.png`,
      AndrewFeldsohn      = `${CDN_HOST}/images/AndrewFeldsohn.png`,
      SaraNunez           = `${CDN_HOST}/images/SaraNunezSpeaker.png`,
      JosePires           = `${CDN_HOST}/images/JosePiresHeads.png`,
      FelipePennacoba     = `${CDN_HOST}/images/FelipePennacobaMartinez.png`,
      RubenLuque          = `${CDN_HOST}/images/RubenLuque.png`,
      EricButler          = `${CDN_HOST}/images/EricButler.png`,
      JoseArias           = `${CDN_HOST}/images/JoseArias.png`,
      CharlieBerger       = `${CDN_HOST}/images/CharlieBerger.png`,
      GlennHoorman        = `${CDN_HOST}/images/GlennHoorman.png`,
      KevinGarvey         = `${CDN_HOST}/images/KevinGarvey.png`,
      CarolinaTaborda     = `${CDN_HOST}/images/CarolinaTaborda.png`,
      RodolfoRivera       = `${CDN_HOST}/images/RodolfoRivera.png`,
      YorkaVelasco        = `${CDN_HOST}/images/YorkaVelasco.png`,
      TravisHahler        = `${CDN_HOST}/images/TravisHahler.png`,
      StephaniePadilla    = `${CDN_HOST}/images/StephaniePadilla.png`,
      MarisolWeymouth     = `${CDN_HOST}/images/MarisolWeymouth.png`,
      AidanONeill         = `${CDN_HOST}/images/AidanONeill.png`,
      HannahSalto         = `${CDN_HOST}/images/HannahSalto.png`,
      SergioGonzalez      = `${CDN_HOST}/images/SergioGonzalez.png`,
      InesOlano           = `${CDN_HOST}/images/InesOlano.png`,
      CaterineLopez       = `${CDN_HOST}/images/CaterineLopez.png`,
      HumbertoGonzalez    = `${CDN_HOST}/images/HumbertoGonzalez.png`;

export type MembersType = {
  name: string;
  position: string;
  image: string;
  quote: string;
  bio: string[];
}

const teamMock: MembersType[] = [
  {
    name: "SaraNunez",
    position: "saraCharge",
    image: SaraNunez,
    quote: "saraQuote",
    bio: null
  },
  {
    name: "KristopherHoce",
    position: "kristopherCharge",
    image: KristopherHoce,
    quote: "kristopherQuote",
    bio: null
  },
  {
    name: "CesarGarcia",
    position: "cesarCharge",
    image: CesarGarci,
    quote: "cesarQuote",
    bio: null
  },
  {
    name: "EdgarRivera",
    position: "edgarCharge",
    image: EdgarRivera,
    quote: "edgarQuote",
    bio: null
  },
  {
    name: "MontySantiago",
    position: "montyCharge",
    image: MontySantiago,
    quote: "montyQuote",
    bio: null
  },
  {
    name: "AndrewFeldsohn",
    position: "andrewCharge",
    image: AndrewFeldsohn,
    quote: "andrewQuote",
    bio: null
  },
  {
    name: "SatyenHarve",
    position: "saytenCharge",
    image: Sayten,
    quote: "saytenQuote",
    bio: null
  },
];

export default teamMock;

export const speakersMock: MembersType[] = [
  {
    name: "SaraNunez",
    position: "saraCharge",
    image: SaraNunez,
    quote: "saraQuote",
    bio: null
  },
  {
    name: 'JosePires',
    position: 'joseCharge',
    image: JosePires,
    quote: 'joseQuote',
    bio: null
  },
  {
    name: 'FelipePennacobaMartinez',
    position: 'felipeCharge',
    image: FelipePennacoba,
    quote: 'felipeQuote',
    bio: [
      'felipeBio1',
      'felipeBio2',
      'felipeBio3'
    ]
  },
  {
    name: 'RubenLuque',
    position: 'rubenCharge',
    image: RubenLuque,
    quote: 'rubenQuote',
    bio: null
  },
  {
    name: 'CarolinaTaborda',
    position: 'carolinaCharge',
    image: CarolinaTaborda,
    quote: 'carolineQuote',
    bio: [
      "carolinaBio1"
    ]

  },
 /*  {
    name: "EdgarRivera",
    position: "edgarCharge",
    image: EdgarRivera,
    quote: "edgarQuote",
    bio: null
  }, */
  {
    name: 'EricButler',
    position: 'ericCharge',
    image: EricButler,
    quote: 'ericQuote',
    bio: [
      'ericBio1'
    ]
  },
  {
    name: 'JoseArias',
    position: 'joseAriasCharge',
    image: JoseArias,
    quote: 'joseAriasQuote',
    bio: [
      "joseAriasBio1",
      "joseAriasBio2",
      "joseAriasBio3",
      "joseAriasBio4",
    ]
  },
  { name: 'CharlieBerger',
    position: 'charlieCharge',
    image: CharlieBerger,
    quote: 'charlieQuote',
    bio: [
      'charlieBio1'
    ]
  },
  {
    name: 'GlennHoorman',
    position: 'glennCharge',
    image: GlennHoorman,
    quote: 'glennQuote',
    bio: [
      "glennBio1",
      "glennBio2",
      "glennBio3",
      "glennBio4",
      "glennBio5",
    ]
  },
  {
    name: 'KevinGarvey',
    position: 'kevinCharge',
    image: KevinGarvey,
    quote: 'kevinQuote',
    bio: [
      "kevinBio1"
    ]
  },
  {
    name: 'RodolfoRivera',
    position: 'rodolfoCharge',
    image: RodolfoRivera,
    quote: 'rodolfoQuote',
    bio: [
      'rodolfobio1',
      'rodolfobio2'
    ]
  },
  {
    name: 'YorkaVelasco',
    position: 'yorkaCharge',
    image: YorkaVelasco,
    quote: 'yorkaQuote',
    bio: [
      'yorkaBio1',
      'yorkaBio2',
      'yorkaBio3',
      'yorkaBio4',
    ]
  },
  {
    name: 'TravisHahler',
    position: 'travisCharge',
    image: TravisHahler,
    quote: 'travisCharge',
    bio: [
      'travisBio1'
    ]
  },
  {
    name: 'StephaniePadilla',
    position: 'stephanieCharge',
    image: StephaniePadilla,
    quote: 'stephanieQuote',
    bio: [
      'stephanieBio1',
      'stephanieBio2',
      'stephanieBio3'
    ]
  }, 
  {
    name: 'MarisolWeymouth',
    position: 'marisolCharge',
    image: MarisolWeymouth,
    quote: 'marisolQuote',
    bio: [
      'marsiolBio1',
      'marsiolBio2',
      'marsiolBio3',
      'marsiolBio4'
    ]
  }, 
  {
    name: 'AidanONeill',
    position: 'aidanCharge',
    image: AidanONeill,
    quote: 'aidanQuote',
    bio: [
      'aidanBio1'
    ]
  },
  {
    name: 'HannahSalto',
    position: 'hannahCharge',
    image: HannahSalto,
    quote: 'hannahQuote',
    bio: [
      'hannahBio1',
      'hannahBio2'
    ]
  }, 
  {
    name: 'SergioGonzalez',
    position: 'sergioCharge',
    image: SergioGonzalez,
    quote: 'sergioQuote',
    bio: [
      'sergioBio1',
      'sergioBio2',
      'sergioBio3',
      'sergioBio4',
    ]
  },
  {
    name: 'InesOlano',
    position: 'inesCharge',
    image: InesOlano,
    quote: 'inesQuote',
    bio: [
      'inesBio1',
      'inesBio2',
      'inesBio3',
    ]
  },
  {
    name: 'CaterineLopez',
    position: 'caterineCharge',
    image: CaterineLopez,
    quote: 'caterineQuote',
    bio: [
      'caterineBio1',
      'caterineBio2',
      'caterineBio3',
      'caterineBio4',
    ]
  },
  {
    name: 'HumbertoGonzalez',
    position: 'humbertoCharge',
    image: HumbertoGonzalez,
    quote: 'humbertoQuote',
    bio: [
      'humbertoBio1',
      'humbertoBio2',
      'humbertoBio3',
    ]
  },
 /*  {
    name: "DTS",
    position: "Dream To Succeed",
    image: `${CDN_HOST}/images/dts-event.webp`,
    quote: "dtsQuote",
    bio: null
  }, */
];
