import { useState, useLayoutEffect, useEffect } from 'react';
import { CDN_HOST } from "env/env.dev";
import "./EventHero.scss";
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks';

const EventHero = () => {
    const { t } = useTranslation(["homeTranslations"])
    const [heightContainer, setHeightContainer] = useState("auto");
    const [viewport] = useViewport();
    const [facebookUrl, setFacebookUrl] = useState('https://www.facebook.com/events/483404576932290/');
    const [youtubeUrl, setYoutubeUrl] = useState('https://www.youtube.com/watch?v=_QHS6X2QOvs');
    const day = new Date().getDate();
    const year = new Date().getFullYear();

    useLayoutEffect(() => {
      if(viewport === 'desktop' || viewport === 'largeDesktop') {
        (window.innerHeight <= 880) 
          ? setHeightContainer("130vh")
          : setHeightContainer("auto");
      }
    }, [viewport]);

   useEffect(() => {
      if (day === 27 && year === 2022) {
        setFacebookUrl('https://www.facebook.com/events/1247191722492456/');
        setYoutubeUrl('https://www.youtube.com/watch?v=mgvKBjAI6nk');
      } 
   }, [day, year]);

  return (
    <section className='EventHero' 
    style={{ backgroundImage: `url(${CDN_HOST}/images/megaevent-hero-blue.png)`, height: `${heightContainer}`}}>
        <div className="Event-content">
            <h1>{t("eventsSubtitle")} {t("eventsSubtitleSecond")}</h1>
            {/* <h2>{t("virtualEvent")}</h2> */}
            <p className='live-traduction'>{t("liveTraduction")}</p>
            {/* <p>{t("liveDescription")}</p> */}
            <p className='join'>{t('join')}</p>
            <p>{`${t("eventTime")}  12:00 - 16:00 EST`}</p>
        </div>
        <a className='main-btn join' href='https://zoom.us/j/95736517223?pwd=ZlI3M09FZDN3UDFURHJ3UDIyQ3ByZz09' target='_blank' rel='noopener noreferrer'>{t("goEventZoom")}</a>
        <a className='main-btn facebook' href={facebookUrl} target='_blank' rel='noopener noreferrer'><i className='fa fa-facebook'></i> {t("goEventFacebook")}</a>
        <a className='main-btn youtube' href={youtubeUrl} target='_blank' rel='noopener noreferrer'> <i className='fa fa-youtube'></i> {t("goEventYoutube")}</a>
        <a className='main-btn linkedin' href='https://www.linkedin.com/events/thefutureofworkforlatinx-creati6958584223771312128/about/' target='_blank' rel='noopener noreferrer'> <i className='fa fa-linkedin'></i> {t("goEventLinkedin")}</a>
    </section>
  )
}

export default EventHero;