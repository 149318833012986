import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import "./Speaker.scss";

interface SpeakerPropTypes {
    speakerImage: string;
    speakerName: string;
    speakerRole: string;
}

const Speaker = ({speakerImage, speakerName, speakerRole}:SpeakerPropTypes) => {
  const { t } = useTranslation(["whoWeAre"]);
;  return (
    <Link className={classNames('Speaker', {
      hidden: speakerName === 'DTS'
    })} to={(speakerName !== 'DTS') ? `/speaker/${speakerName}` : '/'}>
        <img src={speakerImage} alt={speakerName} title={speakerName} loading="lazy" decoding="async" />
        <div className="speaker-data">
            <h3>{t(`${speakerName}`)}</h3>
            <p>{t(`${speakerRole}`)}</p>
        </div>
    </Link>
  )
}

export default Speaker