import React from 'react';
import { CDN_HOST } from 'env/env.dev';
import "./ConferenceSummary.scss";
import { CardDetail  } from 'components/atoms';
import { useTranslation } from 'react-i18next';

const ConferenceSummary = () => {
  const { t } = useTranslation(["homeTranslations"]);

  const arr = [{
    id: 1,
    color: "#f77f00",
    title: t("RPA"),
    description: t("RPAdescription"),
    icon: `${CDN_HOST}/icons/icon-rpa.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-rpa-white.svg`
  }, 
  {
    id: 2,
    color: "#d62246",
    title: t("cybersecurity"),
    description: t("cyberDescription"),
    icon: `${CDN_HOST}/icons/icon-cybersecurity.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-cybersecurity-white.svg`
  },
  {
    id: 3,
    color: undefined,
    title: t("cloudComputing"),
    description: t("cloudDescription"),
    icon: `${CDN_HOST}/icons/icon-computer.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-computer-white.svg`
  },
  {
    id: 4,
    color: "#99D17B",
    title: t("leadershipEntrepreneur"),
    description: t("leadDescription"),
    icon: `${CDN_HOST}/icons/icon-leader.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-leader-white.svg`
  },
  {
    id: 5,
    color: "#5fa8d3",
    title: t("iot"),
    description: t("iotDescription"),
    icon: `${CDN_HOST}/icons/icon-iot.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-iot-white.svg`
  }, 
  {
    id: 6,
    color: "#CC444B",
    title: t("fintech"),
    description: t("fintechDescription"),
    icon: `${CDN_HOST}/icons/icon-fintech.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-fintech-white.svg`
  },
  {
    id: 7,
    color: "#5e7ce2",
    title: t("crm"),
    description: t("crmDescription"),
    icon: `${CDN_HOST}/icons/icon-crm.svg`,
    iconWhite: `${CDN_HOST}/icons/icon-crm-white.svg`
  }
];
  return (
    <section className='ConferenceSummary' 
        style={{ backgroundImage: `url(${CDN_HOST}/images/conference-blue.png)`}}>
          <div className="description">
            <h2>{t("conferenceTopics")}</h2>
            <p>{t("conferenceDescription")}</p>
          </div>

          <div className="cards-wrapper">
            {arr.map(({id, color, title, description, icon, iconWhite}) => (
              <CardDetail 
                key={id}
                color={color}
                title={title}
                content={description}
                icon={icon}
                iconWhite={iconWhite}
                />
            ))}
          </div>
    </section>
  )
}

export default ConferenceSummary